<template>
  <div>
    <auth-layout
      pageClass="register-page"
      style="background-image: url(static/img/full-screen-image-1.png)"
    >
      <div class="row d-flex justify-content-center">
        <!-- <img
          src="https://www.greenesa.com/uploads/news/1631205661Geothermalenergydetailgreenesa.png"
          style="width: 96%"
          alt="avatar"
        /> -->
        <div class="col-md-10">
          <div class="header-text">
            <a
              target="_blink"
              href="https://voltz.io/static/voltz_white_paper.pdf"
              class="ml-2"
            >
              <l-button style="background-color: #079202">White paper</l-button>
            </a>
            <br />
            <a style="font-size: 24px">
              <a style="font-size: 34px">Strengthen</a> energy security to
              support economic growth and develop energy at an environmentally
              friendly level.
            </a>
          </div>
        </div>
        <br />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-5 ml-2 mt-4">
          <center>
            <img :src="require('@/assets/VOLTZ1.png')" style="width: 150px" />
          </center>
          <br />

          <div class="row d-flex" style="color: white">
            <h5>What is “Binance Smart Chain (BSC)” ?</h5>
            <p>
              Binance Smart Chain (BSC) is a blockchain network forked from
              Ethereum, which is designed to be compatible with previously
              developed protocol on Ethereum network called Ethereum Virtual
              Machine (EVM). The ability to create Smart Contract of Ethereum
              Virtual Machine makes it convenient for the third party developers
              to create their own DeFi protocol.
            </p>
            <h6>What is “Decentralized Finance (DeFi)” ? “</h6>
            <p>
              Decentralized Finance” or “DeFi” is a broad term to describe the
              applications and projects built on a blockchain network. It is a
              new decentralized financial system, which unnecessarily relies on
              intermediaries and is able to conduct all transactions as
              Centralized Finance (CeFi), such as generating assets, loaning,
              staking, transferring, etc., via blockchain technology and Smart
              Contra
            </p>
          </div>
          <div class="row d-flex">
            <br />
            <div>
              <a
                target="_blink"
                href="https://bscscan.com/address/0x15A94876de1D1C59198A0760B2ecBbEF1d0410fe"
              >
                <l-button round style="background-color: #079202"
                  >VOLTZ | BSC SCAN</l-button
                >
              </a>
              <a
                target="_blink"
                href="https://bscscan.com/address/0x15A94876de1D1C59198A0760B2ecBbEF1d0410fe"
                class="ml-2"
              >
                <l-button round style="background-color: #079202"
                  >BUY NOW</l-button
                >
              </a>
              <br />
              <a style="color: white; font-size: 12px">
                0x15A94876de1D1C59198A0760B2ecBbEF1d0410fe
              </a>
              <a
                href="#"
                @click="copyText('0x15A94876de1D1C59198A0760B2ecBbEF1d0410fe')"
              >
                <i
                  class="fa fa-clone"
                  style="font-size: 16px; color: white"
                ></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 ml-2 mt-5">
          <center>
            <img
              :src="require('@/assets/tokens/VOLTZ.png')"
              style="width: 150px"
            />
          </center>
          <div class="row d-flex" style="color: white">
            <h5>What Is ERC-20?</h5>
            <p>
              One of the most significant Ethereum tokens is known as ERC-20.
              ERC-20 has emerged as the technical standard; it is used for all
              smart contracts on the Ethereum blockchain for token
              implementation and provides a list of rules that all
              Ethereum-based tokens must follow.ERC-20 is similar, in some
              respects, to bitcoin, Litecoin, and any other cryptocurrency;
              ERC-20 tokens are blockchain-based assets that have value and can
              be sent and received. The primary difference is that instead of
              running on their own blockchain, ERC-20 tokens are issued on the
              Ethereum network.
            </p>
            <h6>ERC-20 Defines a Common List of Rules</h6>
            <p>
              As of August 2021, around 442,647 ERC-20-compatible tokens exist
              on Ethereum's main network. The ERC-20 commands vital importance;
              it defines a common list of rules that all Ethereum tokens must
              adhere to. Some of these rules include how the tokens can be
              transferred, how transactions are approved, how users can access
              data about a token, and the total supply of tokens.
            </p>
          </div>
          <div class="row d-flex">
            <br />
            <div>
              <a
                target="_blink"
                href="https://etherscan.io/token/0x60715e436c37444e29772c0d26a98ae1e8e1a989"
              >
                <l-button round style="background-color: #079202"
                  >VOLTZ | ETH SCAN</l-button
                >
              </a>
              <a
                target="_blink"
                href="https://etherscan.io/token/0x60715e436c37444e29772c0d26a98ae1e8e1a989"
                class="ml-2"
              >
                <l-button round style="background-color: #079202"
                  >BUY NOW</l-button
                >
              </a>
              <br />
              <a style="color: white; font-size: 12px">
                0x60715e436c37444e29772c0d26a98ae1e8e1a989
              </a>
              <a
                href="#"
                @click="copyText('0x60715e436c37444e29772c0d26a98ae1e8e1a989')"
              >
                <i
                  class="fa fa-clone"
                  style="font-size: 16px; color: white"
                ></i>
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </auth-layout>

    <div class="row d-flex m-2 justify-content-center">
      <div class="col-md-10 mt-5">
        <center>
          <h2 style="font-weight: 600">clean energy renewable energy</h2>
        </center>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              What is clean energy?
            </a>
            <p>
              Clean energy is energy obtained from natural resources or
              infinitely repetitive processes. Clean energy is the energy of the
              future that is environmentally friendly. because it does not
              affect does not harm the environment or releases carbon dioxide
              into the atmosphere like any other form of energy, whether during
              production, processing, utilization to waste management Making
              clean energy become a new alternative energy that is very
              important today.
            </p>

            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              clean energy source
            </a>
            <p>
              Clean energy can be divided into two more categories: energy that
              comes from nature such as wind, water, sunlight, and geothermal.
              Another type of clean energy is energy that can be generated by
              humans, such as waste energy, bioenergy and biomass energy.
              Produced from waste materials such as napier grass, corn, bagasse,
              rice straw, etc., including the livestock sector such as manure,
              important clean energy sources are as follows.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-2 justify-content-center">
      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              Hydro power (Hydro)
            </a>
            <p>
              Hydropower is a natural renewable energy generated from reservoirs
              or dams. Based on the principle of movement of water to move from
              high to low. The first step is to store water in reservoirs or
              dams to collect potential energy. and when the required amount of
              water is obtained Therefore, open the water door to allow water to
              flow out to rotate the blades of water turbines and generators or
              generators in hydroelectric power plants with kinetic energy. and
              ultimately acquires electric power for Thailand electric power
              from water Hydroelectricity is a renewable energy that produces
              more energy than any other form of energy.
            </p>
            <center>
              <img
                src="https://cdn5.vectorstock.com/i/1000x1000/27/34/renewable-energy-infographic-hydro-power-station-vector-35612734.jpg"
                style="width: 96%"
                alt="avatar"
              />
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              Wind power (Wind)
            </a>
            <p>
              Wind power is another type of clean energy that never runs out. We
              can generate electricity from wind power by using wind turbines to
              convert the kinetic energy produced by the movement of the wind.
              It is then converted into another mechanical energy to be used to
              spin the generator and convert mechanical energy into electrical
              energy. Most of them are usually produced in hilly areas.
              mountains in remote areas or coastal areas which will have both
              sea breeze and offshore wind The present may be found in the form
              of windmill fields. But in Thailand, there will be obstacles in
              terms of wind speed. Therefore, it is unable to fully exploit the
              potential of wind energy.
            </p>
            <center>
              <img
                src="https://ppprime.co.th/tluxepower/wp-content/uploads/2019/08/GettyImages-500328050-F.jpg"
                style="width: 96%"
                alt="avatar"
              />
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              Solar energy
            </a>
            <p>
              Solar energy is a clean energy alternative that utilizes natural
              energy sources like sunlight. Which is one of the world's most
              abundant resources to be converted into energy with a photovoltaic
              system that will convert solar energy into electricity. This
              ranges from popular household solar rooftop systems to solar farms
              or photovoltaic power plants. In addition to electric power Solar
              energy can also be converted into heat energy.
            </p>
            <center>
              <img
                src="https://philsolar.ph/wp-content/uploads/2014/05/how-solar-power-works.jpg"
                style="width: 96%"
                alt="avatar"
              />
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              Bioenergy/Biomass Energy (Biogas/Biomass)
            </a>
            <p>
              Bioenergy and biomass energy is the energy obtained from the
              processing of agricultural waste such as napier grass, corn,
              bagasse, rice straw, etc., as well as the livestock sector such as
              manure, into gas and electric power. incineration, gas production
              and fermentation of agricultural products and biomass Bioenergy
              not only promotes the use of renewable energy. But it also helps
              support farmers to have sustainable income as well.
            </p>
            <center>
              <img
                src="https://www.ecomena.org/wp-content/uploads/2012/04/biomass_resources.jpg"
                style="width: 96%"
                alt="avatar"
              />
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              Geothermal Energy
            </a>
            <p>
              Geothermal energy is energy obtained by conducting heat stored
              beneath the earth's surface. which is caused by the decomposition
              of radioactive substances such as potassium, uranium, thorium,
              etc., in the earth's crust to be utilized by the production of
              renewable energy It uses the force of high-pressure steam
              accumulated under the rock to spin turbines and power generators.
              and eventually become electric power.
            </p>
            <center>
              <img
                src="https://www.greenesa.com/uploads/news/1631205661Geothermalenergydetailgreenesa.png"
                style="width: 96%"
                alt="avatar"
              />
            </center>
          </div>
        </div>
      </div>

      <div class="col-md-10 mt-5">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              The advantages of clean energy
            </a>
            <p>
              Clean energy can be considered as a safe and environmentally
              friendly source of natural renewable energy. The obvious advantage
              of clean energy is that it is an inexhaustible source of energy.
              Unlike the limited availability of fossil fuels and can be
              exhausted some day in the future In addition, the production
              process, processing, use. or limiting the waste of clean energy,
              it does not destroy or affect the environment and atmosphere like
              other energy, which will emit carbon dioxide in the production,
              processing, utilization. and manage waste or waste and bring about
              global warming
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-1">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              How can we use clean energy?
            </a>
            <p>
              Energy is an extremely important factor for human life. Especially
              electric power that is a fundamental factor from the household
              sector, transportation sector to various industrial sectors by
              acting as a renewable energy source, the original energy source
              derived from fossil fuels. This clean energy can also be converted
              into various energy so that it can be used in a variety of ways,
              such as heat energy, electric power, light energy, etc., whether
              used in the solar food preservation process. to the production of
              light energy from solar energy in areas that are far from the
              power poles and help reduce the problem of energy shortage in the
              future as well
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-1">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <a class="mt-3" style="font-size: 20px; font-weight: 600">
              The importance of clean energy for the future world
            </a>
            <p>
              The main energy we use today comes from almost all of the energy
              sources that are used up. In addition, the demand for energy Day
              by day, there are more but the amount of those energy is limited.
              Which can be exhausted one day or another in the future.
              Therefore, clean energy is important in terms of being a renewable
              energy source that will support the needs and needs of
              applications that will replace energy from fuel in the form of old
              style It also helps prevent various environmental problems that
              may arise and mitigate the impact of various pollution problems
              such as global warming and the greenhouse effect. resulting from
              the use of current energy which affects the living conditions of
              all life on earth in a wide area Clean energy is therefore
              considered to be the energy of the future that will help drive the
              world to spin sustainably.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row d-flex justify-content-center"
      style="background-color: #079202; color: white"
    >
      <div class="col-md-10 mt-1">
        <div class="pt-5 pb-5">
          <div class="row d-flex justify-content-center">
            <div class="col-md-10">
              <div class="row justify-content-end">
                <br />

                <div class="m-4">
                  <i class="fa fa-users" style="color: white"></i>
                  <a target="_blink" style="margin-left: 5px"
                    >Email: admin@voltz.io
                  </a>
                  <br />
                  <!-- <i class="fa fa-users" style="color: white"></i>
                  Contact as Line: @voltz.io -->
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="m-2" style="background-color: #079202; color: white">
      <div class="pt-5 pb-5">
        <center>
          <div class="row d-flex justify-content-center">
            <div class="col-md-10">
              <div class="row justify-content-end">
                <br />

                <div class="m-4">
                  <a href="/terms" target="_blink"> Terms & Conditions </a>
                  <br />
                  <i class="fa fa-users" style="color: white"></i>
                  Contact as Line: @hashbx
                </div>

                <br />
              </div>
            </div>
          </div>
        </center>
      </div>
    </div> -->
  </div>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      priceBTCUSDT: "",
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    copyText(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    setInterval(() => {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => {
          this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 15000);
    axios
      .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
      .then((response) => {
        this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style></style>
