<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Affiliate"></top-navbar>

      <div class="m-1">
        <div class="mt-3">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                style="
                  background-color: #202634;
                  color: #14b3f9;
                  border-radius: 5px;
                  border-style: solid;
                  border-color: #14b3f9;
                "
              >
                <div class="p-3">
                  <center>
                    <a style="font-size: 20px">Affiliate Program</a>
                  </center>
                </div>
              </div>
              <div
                class="mt-3"
                style="
                  background-color: #202634;
                  border-radius: 5px;
                  border-style: solid;
                  border-color: #14b3f9;
                "
              >
                <div class="row p-2" style="color: white">
                  <div class="col-md-5 mt-2">
                    <div style="background-color: #14b3f9">
                      <center>
                        <i class="fa fa-user"></i>
                        <a>My Affiliate</a>
                      </center>
                    </div>
                    <div style="border-style: solid; border-color: #14b3f9">
                      <br />
                      <center>
                        <i class="fa fa-user-plus"></i>
                        <br />
                        <a style="font-size: 18px; color: #14b3f9">
                          REFERRAL
                        </a>
                        <br />
                        <br />
                        <a style="font-size: 20px">{{ table_rows.length }}</a>
                      </center>
                      <br />
                      <br />
                    </div>
                  </div>
                  <div class="col-md-7" style="font-size: 14px">
                    <div class="m-3">
                      <br />
                      <br />
                      <a style="color: #14b3f9; font-weight: 600">
                        Affiliate Link</a
                      >
                      <br />
                      <input
                        type="text"
                        disabled
                        v-model="affLink"
                        placeholder="Affiliate Link"
                        class="form-control"
                      />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="m-1" v-if="table_rows.length > 0">
        <div class="mt-3">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                class="mt-3"
                style="
                  background-color: #202634;
                  border-radius: 5px;
                  border-style: solid;
                  border-color: #14b3f9;
                "
              >
                <div class="m-4" style="color: white">
                  <br />
                  <el-table class="table-striped" :data="table_rows">
                    <el-table-column
                      label="email"
                      property="email"
                    ></el-table-column>
                  </el-table>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="m-1" v-else>
        <div class="mt-3">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                class="mt-3"
                style="
                  background-color: #202634;
                  border-radius: 5px;
                  border-style: solid;
                  border-color: #14b3f9;
                "
              >
                <div class="m-4" style="color: white">
                  <br />
                  <center>
                    <i class="fa fa-user-plus" style="font-size: 26px"></i>
                    <a class="ml-3" style="font-weight: 500">
                      No Users referral
                    </a>
                  </center>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import VueTradingView from "vue-trading-view";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import { SITE_URL } from "../../../config";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import moment from "moment";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    VueTradingView,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      affID: "",
      affLink: `${SITE_URL}`,
      table_rows: [],
    };
  },
  computed: mapGetters(["getUser"]),
  methods: {
    ...mapActions(["Logout"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    getdata() {
      this.table_rows = [];
      user
        .refers()
        .then((res) => {
          this.ready = true;
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_rows.push(e);
          });
        })
        .catch((error) => {});
    },
  },
  created() {
    this.affID = this.getUser.hash;
    this.affLink = SITE_URL + "/ref/" + this.getUser.hash;

    this.getdata();
  },
};
</script>
