var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'REGISTER',
          icon: 'nc-icon nc-chart-pie-35',
          path: '/adminregister',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'fa fa-user',
          path: '/adminusers',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'ADD MONEY',
          icon: 'fa fa-money',
          path: '/adminaddmoney',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'KYC Verification',
          icon: 'fa fa-user',
          path: '/adminkyc',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Withdrawals List',
          icon: 'fa fa-key',
          path: '/adminwithdrawalslist',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Withdrawals List',
          icon: 'fa fa-key',
          path: '/admincreateminingpool',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Fund Summary',
          icon: 'fa fa-bar-chart',
          path: '/adminfund',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{staticClass:"m-3 mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"Register by admin"}},[_c('div',[_c('fg-input',{attrs:{"label":"Email address","type":"email","placeholder":"Enter email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('fg-input',{attrs:{"type":"password","label":"Passward","placeholder":"Enter Passward"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('fg-input',{attrs:{"type":"password","label":"Confirm Passward","placeholder":"Confirm Passward"},model:{value:(_vm.confirmPass),callback:function ($$v) {_vm.confirmPass=$$v},expression:"confirmPass"}}),_c('center',[_c('button',{staticClass:"btn btn-fill btn-info",staticStyle:{"background-color":"green"},attrs:{"type":"submit"},on:{"click":function($event){return _vm.createAccount()}}},[_vm._v(" Create Account ")])])],1)])],1)])]),_c('content-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }