var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'ADD MONEY',
          icon: 'fa fa-money',
          path: '/adminaddmoney',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'KYC Verification',
          icon: 'fa fa-user',
          path: '/adminkyc',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Withdrawals List',
          icon: 'fa fa-key',
          path: '/adminwithdrawalslist',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contract',
          icon: 'fa fa-signal',
          path: '/adminccontract',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSACTIONS',
          icon: 'fa fa-calendar',
          path: '/admintransactions',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'View User',
          icon: 'fa fa-user',
          path: '/adminviewuser',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'All Deposit',
          icon: 'fa fa-gift',
          path: '/admindepositall',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Fund Summary',
          icon: 'fa fa-bar-chart',
          path: '/adminfund',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{staticClass:"m-1 mt-3"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6"},[_c('card',{staticClass:"stacked-form"},[_c('fg-input',{attrs:{"disabled":"","label":"type_invest","placeholder":"type_invest"},model:{value:(_vm.type_invest),callback:function ($$v) {_vm.type_invest=$$v},expression:"type_invest"}}),_c('fg-input',{attrs:{"label":"currencyIN","placeholder":"currencyIN"},model:{value:(_vm.currencyIN),callback:function ($$v) {_vm.currencyIN=$$v},expression:"currencyIN"}}),_c('fg-input',{attrs:{"label":"currencyOUT","placeholder":"currencyOUT","disabled":""},model:{value:(_vm.currencyOUT),callback:function ($$v) {_vm.currencyOUT=$$v},expression:"currencyOUT"}}),_c('fg-input',{attrs:{"label":"currencyCOST","placeholder":"currencyCOST","disabled":""},model:{value:(_vm.currencyCOST),callback:function ($$v) {_vm.currencyCOST=$$v},expression:"currencyCOST"}}),_c('fg-input',{attrs:{"label":"cost_ratio","placeholder":"cost_ratio"},model:{value:(_vm.cost_ratio),callback:function ($$v) {_vm.cost_ratio=$$v},expression:"cost_ratio"}}),_c('fg-input',{attrs:{"label":"period ( days )","placeholder":"period"},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('fg-input',{attrs:{"label":"min_invest ( S9th )","placeholder":"min_invest"},model:{value:(_vm.min_invest),callback:function ($$v) {_vm.min_invest=$$v},expression:"min_invest"}}),_c('fg-input',{attrs:{"label":"total","placeholder":"total"},model:{value:(_vm.total),callback:function ($$v) {_vm.total=$$v},expression:"total"}}),_vm._v(" Power hash "+_vm._s(_vm.powerHash / 1000)+" TH/s "),_c('div',[_c('br'),_c('center',[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.createInvest()}}},[_vm._v(" Create Invest ")])])],1)],1)],1)])]),_c('content-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }