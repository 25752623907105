// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Managepool from "src/pages/Dashboard/Dashboard/ManagePool.vue";
import Transection from "src/pages/Dashboard/Dashboard/Transection.vue";
import Transfer from "src/pages/Dashboard/Dashboard/Transfer.vue";
import MyFund from "src/pages/Dashboard/Dashboard/MyFund.vue";
import MyPower from "src/pages/Dashboard/Dashboard/MyPower.vue";
import Profile from "src/pages/Dashboard/Dashboard/Profile.vue";
import MyEarn from "src/pages/Dashboard/Dashboard/MyEarn.vue";
import MyAffiliate from "src/pages/Dashboard/Dashboard/Affiliate.vue";
import Staking from "src/pages/Dashboard/Dashboard/Staking.vue";
import Deposit from "src/pages/Dashboard/Dashboard/Deposit.vue";
import History from "src/pages/Dashboard/Dashboard/History.vue";
import Farm from "src/pages/Dashboard/Dashboard/Farm.vue";
import ChangePass from "src/pages/Dashboard/Dashboard/ChangePass.vue";

import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Referral from "src/pages/Dashboard/Pages/Referral.vue";
import Home from "src/pages/Dashboard/Pages/Home.vue";
import Terms from "src/pages/Dashboard/Pages/Terms.vue";
import ForgotPassWord from "src/pages/Dashboard/Pages/ForgotPassWord.vue";
import Resetpasswordverification from "src/pages/Dashboard/Pages/resetpasswordverification.vue";
import Resetpass from "src/pages/Dashboard/Pages/resetpass.vue";

// Admin
import AdminRegisterby from "src/pages/Admin/Register.vue";
import AdminUsers from "src/pages/Admin/Users.vue";
import AdminAddmoney from "src/pages/Admin/AddMoney.vue";
import AdminKYC from "src/pages/Admin/KYC.vue";
import AdminProfile from "src/pages/Admin/ProfileKYC.vue";
import AdminWithdrawalsList from "src/pages/Admin/WithdrawalsList.vue";
import AdminCreateMiningPool from "src/pages/Admin/CreateMiningPool.vue";
import AdminCreateFarm from "src/pages/Admin/CreateFarm.vue";
import AdminContract from "src/pages/Admin/Contract.vue";
import AdminCreateStaking from "src/pages/Admin/CreateStaking.vue";
import AdminTransaction from "src/pages/Admin/Transactions.vue";
import AdminViewUser from "src/pages/Admin/ViewUser.vue";
import AdminDepositAll from "src/pages/Admin/DepositAll.vue";
import AdminFundSummary from "src/pages/Admin/FundSummary.vue";

import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let homePage = {
  path: "/",
  name: "Home",
  component: Home,
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
};

let router = new Router({
  mode: "history",
  routes: [
    loginPage,
    homePage,
    registerPage,
    {
      path: "/ref/:ref",
      name: "referral",
      component: Referral,
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: ForgotPassWord,
    },
    {
      path: "/resetpasswordverification",
      name: "resetpasswordverification",
      component: Resetpasswordverification,
    },
    {
      path: "/resetpass/:token",
      name: "resetpass",
      component: Resetpass,
    },
    {
      path: "/overview",
      name: "Overview",
      component: Overview,
      meta: { requiresAuth: true },
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/managepool",
      name: "Managepool",
      component: Managepool,
      meta: { requiresAuth: true },
    },
    {
      path: "/transection",
      name: "Transection",
      component: Transection,
      meta: { requiresAuth: true },
    },
    {
      path: "/changePass",
      name: "changePass",
      component: ChangePass,
      meta: { requiresAuth: true },
    },
    {
      path: "/transfer",
      name: "transfer",
      component: Transfer,
      meta: { requiresAuth: true },
    },
    {
      path: "/Withdrawal",
      name: "Withdrawal",
      component: MyFund,
      meta: { requiresAuth: true },
    },
    {
      path: "/fund",
      name: "fund",
      component: MyPower,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true },
    },
    {
      path: "/myearn",
      name: "myearn",
      component: MyEarn,
      meta: { requiresAuth: true },
    },
    {
      path: "/affiliate",
      name: "affiliate",
      component: MyAffiliate,
      meta: { requiresAuth: true },
    },
    {
      path: "/staking",
      name: "staking",
      component: Staking,
      meta: { requiresAuth: true },
    },
    {
      path: "/deposit",
      name: "deposit",
      component: Deposit,
      meta: { requiresAuth: true },
    },
    {
      path: "/history",
      name: "history",
      component: History,
      meta: { requiresAuth: true },
    },
    {
      path: "/farm",
      name: "farm",
      component: Farm,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminregister",
      name: "register",
      component: AdminRegisterby,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminusers",
      name: "adminusers",
      component: AdminUsers,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminaddmoney",
      name: "adminaddmoney",
      component: AdminAddmoney,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminkyc",
      name: "adminkyc",
      component: AdminKYC,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminwithdrawalslist",
      name: "adminwithdrawalslist",
      component: AdminWithdrawalsList,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminprofile/:user_id",
      name: "adminprofile",
      component: AdminProfile,
      meta: { requiresAuth: true },
    },
    {
      path: "/admincreateminingpool",
      name: "admincreateminingpool",
      component: AdminCreateMiningPool,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminccontract",
      name: "adminccontract",
      component: AdminContract,
      meta: { requiresAuth: true },
    },
    {
      path: "/admincreatestaking",
      name: "admincreatestaking",
      component: AdminCreateStaking,
      meta: { requiresAuth: true },
    },
    {
      path: "/admincreatefarm",
      name: "admincreatefarm",
      component: AdminCreateFarm,
      meta: { requiresAuth: true },
    },
    {
      path: "/admintransactions",
      name: "admintransactions",
      component: AdminTransaction,
      meta: { requiresAuth: true },
    },
    {
      path: "/admintransactions/:email",
      name: "admintransactionsemail",
      component: AdminTransaction,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminviewuser",
      name: "adminviewuser",
      component: AdminViewUser,
      meta: { requiresAuth: true },
    },
    {
      path: "/admindepositall",
      name: "admindepositall",
      component: AdminDepositAll,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminviewuser/:address",
      name: "adminviewuseraddress",
      component: AdminViewUser,
      meta: { requiresAuth: true },
    },
    {
      path: "/adminfund",
      name: "adminfund",
      component: AdminFundSummary,
      meta: { requiresAuth: true },
    },

    { path: "*", component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
