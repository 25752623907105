<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-10 col-md-10 col-sm-10">
        <ValidationObserver>
          <form>
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Terms & Conditions</h3>
                </div>
                <h5>1. GENERAL</h5>
                <a>
                  For the purpose of these terms, HashBX Global Co. Ltd. will be
                  referred to as the “company” and all users of the company’s
                  services shall be referred to as the “customer”. The website
                  operated by HashBX Global Co. Ltd. at https://hashbx.com,
                  including all sub-sites and APIs will be referred to as the
                  “website”.
                </a>
                <li>
                  We reserve the right to change these Terms at any time, in our
                  sole discretion. Any such changes in respect of your use of
                  the Services will take effect when posted on the Website. If
                  you have supplied us with an email address, we will also
                  notify you by email of changes to these Terms
                </li>
                <li>
                  It is your responsibility to read the Terms carefully on each
                  occasion you use the Services. Your continued use of the
                  Services shall signify your acceptance to be bound by the
                  current Terms
                </li>
                <li>
                  Failure or delay by Blockchain in enforcing or partially
                  enforcing any provision of these Terms shall not be construed
                  as a waiver of any rights.
                </li>
                <br />

                <h5>2. ACCOUNT</h5>
                <li>
                  To access Bitcoin Mining services, you need to create an
                  account. You must be the only user of this account and you
                  must keep your password safe and confidential. We will not
                  repay for the damages done to your account by 3rd party. If
                  you don't follow these terms or if you try to harm our
                  services or it's users in any way, your account might get
                  disabled permanently, without further notice and without any
                  liability.
                </li>
                <br />
                <h5>3. SERVICE</h5>
                <li>
                  You must not interfere in the operation of our services. You
                  will not access our services in any form other than this
                  website. (www.hashbx.com). Bitcoin mining is a risky business
                  and ROI is dependent on many factors, including electricity,
                  price movements, local laws, and difficult changes.
                  Maintenance costs depend on the BTC's price and difficulty in
                  mining, and in case the customer does not profit from the
                  service, you can decide whether to use the service or stop
                  using the service temporarily. Checking customer details is
                  subject to Wallet Address. If there are any failures or
                  miscommunications, we will not be responsible for any losses.
                  The company will do best to solve potential problems. The user
                  is responsible for verifying the address and accuracy of the
                  information sent. The company reserves the right to change the
                  terms without prior notice.
                </li>
                <br />
                <h5>4. PRIVACY POLICY & SECURITY</h5>
                <li>
                  We endeavor to take all reasonable steps to protect your
                  personal information. However, we cannot guarantee the
                  security of any data you disclose online. You accept the
                  inherent security risks of providing information and dealing
                  online over the Internet and will not hold us responsible for
                  any breach of security.
                </li>
                <br />
                <h5>5. RISK NOTICE</h5>
                <li>
                  Cryptocurrencies are not backed or value guaranteed by any
                  financial institution; when purchasing cryptocurrency or
                  hashpower unit the customer assumes all risk that they may
                  become worthless in value. Customers should research and
                  consider the risks before use our platform. The company makes
                  absolutely no guarantee about the future value of any
                  currencies traded on our system. And in case a group of people
                  impersonate themselves as a part of Hashbx Global without a
                  proper notice on a written paper and they provide wrong
                  information or information that may cause the problem to the
                  company. The company reserves the right not to be responsible
                  for it.
                </li>
                <br />
                <h5>6. UNINSURED DEPOSITS</h5>
                <li>
                  Customer funds sent to the company are not insured by any
                  insurance company, banking institution or government. These
                  funds are backed only by the registered capital of the
                  company. Deposits held on the website should not be considered
                  equivalent to a bank account. The company is not a bank, and
                  website accounts are not bank accounts.
                </li>
                <br />
                <h5>7. NO THIRD PARTY WITHDRAWAL & TRANSFER</h5>
                <li>
                  All withdrawals or transfer made by the customer must be
                  received directly by the customer. Deposits made to the
                  customer's account must be initiated by the customer and come
                  directly from the customer. Withdrawals to any person or
                  company not listed as the owner of the customer account are
                  prohibited.
                </li>
                <br />
                <h5>
                  8. NO PYRAMID / PONZI / CASH GIFTING / LENDING / MONEY GAMES
                </h5>
                <li>
                  It is prohibited to directly or indirectly send or receive
                  funds from or to any investment scheme, pyramid scheme, ponzi
                  scheme, cash gifting, money donation scheme, money lending
                  program or any other type of money game organization, group,
                  website or scheme. Any customer found to be engaged in any way
                  with a money game, as an organizer or end user, will have
                  their account immediately terminated.
                </li>
                <br />
                <h5>9. WEBSITE ACCOUNT</h5>
                <li>
                  Customers are limited to creating only one account on the
                  website. In order to engage in any trades via the Website, the
                  customer must create an account and provide any requested
                  information; including any additional information requested
                  beyond fields listed on the standard sign-up forms. When the
                  customer creates an account, they must agree to: (a) create a
                  strong password that is not used for any other website or
                  online service; (b) provide accurate and truthful information;
                  (c) maintain and promptly update the customer account
                  information; (d) maintain the security of the account by
                  protecting the password and restricting access to the account;
                  (e) promptly notify the company if the customer discovers or
                  otherwise suspect any security breaches related to the
                  account; and (f) take responsibility for all activities that
                  occur under the customer's account and accept all risks of any
                  authorized or unauthorized access to the customer's account
                  The company may shut down and return funds for any account at
                  the sole discretion of the company. The company reserves the
                  right to temporarily deny access to some or all customer
                  accounts at any time.
                </li>
                <br />
                <h5>10. VERIFICATION</h5>
                <li>
                  The company may at any time require verification information
                  from the customer in order to continue using services provided
                  by the company. This verification information may include, but
                  is not limited to, customer name, address, date of birth, scan
                  of government ID, address verification documents.
                </li>
                <br />
                <h5>11. ANTI-MONEY LAUNDERING (AML)</h5>
                <li>
                  It is expressly prohibited for the customer to utilize their
                  account for the purpose for cleaning money, hiding money, in
                  any way attempting to obscure the source of funds, or
                  attempting to circumvent financial regulations or
                  restrictions. The company will actively analyze customer
                  account activity for any signs of money laundering; any
                  customer whom, in the opinion of the company, has the
                  appearance of involvement in money laundering activities will
                  have their account terminated.
                </li>
                <br />
                <h5>12. INFORMATION PRIVACY</h5>
                <li>
                  When legally obligated, the company will provide customer
                  information to Thai government agencies upon legal request,
                  court order or presentation of warrant, or written from legal
                  authority. The HashBX Global Co. Ltd. reserves the right to
                  change these terms at any time without prior notice or
                  notification of the changes.
                </li>
                <br />
                <h5>13. NO REFUNDABLE</h5>
                <li>
                  Customer only hold the number of processing units. The
                  operating equipment is the property of the Company. And the
                  company has no policy to buy the processor back from the
                  customer, in any case.
                </li>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import { SITE_URL, RECPATCHA_KEY } from "../../../config";
import nativeToast from "native-toast";
import auth from "@/api/auth";
import { mapGetters, mapActions } from "vuex";

import { load } from "recaptcha-v3";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    factor: "",
    token: null,
    loading: false,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      factor: "",
    };
  },
  computed: mapGetters(["getToken", "getUser"]),

  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "setSecure",
      "fetchBalance",
      "setBalance",
    ]),

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {},
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
