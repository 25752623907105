<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="STAKING"></top-navbar>

      <div class="mt-n5" style="background-color: #486382; color: white">
        <div class="p-4">
          <h2 style="font-weight: 600">STAKING</h2>
          <p>Staking Liquidity Pool (LP) to HBX.</p>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="mr-4 mt-2">
          <a href="/myearn" style="color: #14b3f9; font-weight: 600">My Pool</a>
        </div>
      </div>

      <br />

      <div class="" v-if="listInvest.length > 0">
        <div v-for="invest in listInvest" :key="invest">
          <div
            v-if="invest.type == 'STAKING' && invest.avail >= invest.min_invest"
            class="row justify-content-center"
          >
            <template>
              <div
                class="col-11"
                style="background-color: white"
                v-on:click="expand(invest._id)"
              >
                <div class="row">
                  <div class="col-5 mt-3">
                    <a style="font-size: 14px; font-weight: 600">
                      <img
                        :src="
                          require(`@/assets/tokens/${invest.currencyIN}.png`)
                        "
                        style="max-width: 25%; border-radius: 50%"
                      />
                      {{ invest.currencyIN }}
                    </a>
                  </div>
                  <div class="col-2">
                    <a style="font-size: 12px"> Earned </a>
                    <br />
                    <center>
                      <a
                        style="
                          font-size: 12px;
                          font-weight: 500;
                          color: #14b3f9;
                        "
                      >
                        <img
                          :src="
                            require(`@/assets/tokens/${invest.currencyOUT}.png`)
                          "
                          style="
                            max-width: 25px;
                            border-radius: 50%;
                            border: 2px solid green;
                          "
                        />
                      </a>
                    </center>
                  </div>
                  <div class="col-3">
                    <a style="font-size: 12px"> APR </a>
                    <br />
                    <a style="font-size: 12px; font-weight: 600">
                      <a
                        v-for="(item, index) in setting"
                        :key="index"
                        style="font-size: 14px; font-weight: 600; color: green"
                      >
                        <a v-if="invest.currencyOUT + 'USDT' == item.name">
                          {{
                            (
                              ((invest.ratio -
                                invest.cost_ratio * invest.period) /
                                invest.period /
                                (invest.cost_ratio * invest.period)) *
                              365 *
                              100
                            )
                              .toFixed(1)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}%
                        </a>
                      </a>
                    </a>
                  </div>
                  <div class="col-1">
                    <div v-if="expanded == invest._id">
                      <a style="font-size: 16px; font-weight: 600">
                        <i class="fa fa-angle-up"></i>
                      </a>
                    </div>
                    <div v-else>
                      <a style="font-size: 16px; font-weight: 600">
                        <i class="fa fa-angle-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </template>
          </div>
          <div v-if="expanded == invest._id" style="background-color: #edeff0">
            <form method="#" action="#" @submit.prevent class="ml-1 mr-1">
              <br />
              <p style="font-weight: 500; font-size: 14px">Conditions:</p>
              <div class="ml-3" style="font-size: 14px">
                <li>
                  Minimum {{ invest.type }}
                  <a style="color: #14b3f9; font-weight: 500">
                    {{ invest.min_invest }}
                  </a>
                  {{ invest.currencyIN }}
                </li>
                <li>
                  Liquidity Pool Return after
                  <a style="color: #14b3f9; font-weight: 500"
                    >{{ invest.period }}
                  </a>
                  Days
                </li>
                <li>
                  Daily Rewards
                  <a style="color: #14b3f9; font-weight: 500">
                    {{ parseFloat(invest.ratio / invest.period).toFixed(2) }}
                  </a>
                  {{ invest.currencyOUT }} / {{ invest.currencyIN }}
                </li>
                <li>
                  STAKING Supply
                  <a style="color: #14b3f9; font-weight: 500; font-size: 14px">
                    {{ invest.cost_ratio }}
                  </a>
                  {{ invest.currencyCOST }} per Day
                </li>
              </div>
              <div>
                <br />
                <a style="font-size: 14px; font-weight: 500"
                  >Amount {{ invest.currencyIN }} ( Liquidity Pool )</a
                >
                <br />
                <br />
                <fg-input
                  v-model="amountPowerCoin"
                  type="number"
                  :placeholder="
                    'Enter ' +
                    invest.currencyIN +
                    ' Amount | Available : ' +
                    invest.avail +
                    ' ' +
                    invest.currencyIN
                  "
                >
                </fg-input>
                <div class="row justify-content-end">
                  <div class="mr-3">
                    <p style="font-size: 14px">
                      <a style="color: #14b3f9">
                        {{
                          parseFloat(
                            getBalance[invest.currencyIN].available
                          ).toFixed(2)
                        }}
                      </a>

                      {{ invest.currencyIN }}
                    </p>
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div class="mr-3 mt-n2">
                    <p style="font-size: 14px">
                      <a style="color: #14b3f9">
                        {{ parseFloat(getBalance.HBX.available).toFixed(2) }}
                      </a>
                      ( HBX )
                    </p>
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div class="mr-3 mt-n2">
                    <p style="font-size: 12px">
                      <a
                        style="text-decoration-line: underline; color: #818080"
                        href="https://exchange.pancakeswap.finance/#/add/BNB/0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                      >
                        Get {{ invest.currencyIN }} LP tokens
                      </a>
                    </p>
                  </div>
                </div>

                <p style="font-weight: 500; font-size: 14px">Approximately:</p>
                <div class="ml-3" style="font-size: 14px">
                  <li>
                    {{ invest.type }}
                    <a
                      style="color: #14b3f9; font-weight: 500; font-size: 16px"
                      >{{ amountPowerCoin }}</a
                    >
                    {{ invest.currencyIN }}
                  </li>
                  <li>
                    STAKING Supply
                    <a style="color: #14b3f9; font-weight: 500">
                      {{
                        (
                          amountPowerCoin *
                          invest.cost_ratio *
                          invest.period
                        ).toFixed(2)
                      }}
                    </a>
                    {{ invest.currencyCOST }} per {{ invest.period }} Days
                  </li>
                  <li>
                    Daily Rewards
                    <a style="color: #14b3f9; font-weight: 500">
                      {{
                        parseFloat(
                          (invest.ratio / invest.period) * amountPowerCoin
                        ).toFixed(2)
                      }}
                    </a>
                    {{ invest.currencyOUT }}
                  </li>

                  <li>
                    Total Rewards 15 Days
                    <a style="color: #14b3f9; font-weight: 500">
                      {{
                        parseFloat(invest.ratio * amountPowerCoin).toFixed(2)
                      }}
                    </a>
                    {{ invest.currencyOUT }}
                  </li>
                </div>

                <br />

                <center>
                  <div>
                    <l-button
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="submitInvest(invest)"
                    >
                      STAKING POOL
                    </l-button>
                  </div>
                </center>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>

      <br />

      <!-- <div class="m-3 mt-3" v-if="listInvest.length > 0">
        <div v-for="invest in listInvest" :key="invest">
          <div
            v-if="invest.type == 'STAKING' && invest.avail >= invest.min_invest"
            class="row justify-content-center"
          >
            <div class="col-md-8">
              <card class="stacked-form">
                <form method="#" action="#" @submit.prevent>
                  <center>
                    <div style="">
                      <img
                        :src="
                          require(`@/assets/tokens/${invest.currencyIN}.png`)
                        "
                        style="max-width: 15%; border-radius: 50%"
                      />

                      <br />
                      <a style="font-size: 18px"> {{ invest.currencyIN }} </a>
                      <br />
                    </div>
                  </center>

                  <br />

                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-6">
                          <center>
                            <a style="font-weight: 600; font-size: 16px">
                              APY :
                            </a>
                          </center>
                        </div>
                        <div class="col-6">
                          <center>
                            <a v-for="(item, index) in setting" :key="index">
                              <a
                                v-if="invest.currencyOUT + 'USDT' == item.name"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  color: green;
                                "
                              >
                                {{
                                  (
                                    Math.pow(
                                      (invest.ratio -
                                        invest.cost_ratio * invest.period) /
                                        invest.period /
                                        (invest.cost_ratio * invest.period) +
                                        1,
                                      365
                                    ) * 100
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}
                              </a>
                            </a>
                            %
                          </center>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <center>
                            <a style="font-weight: 600; font-size: 16px">
                              APR :
                            </a>
                          </center>
                        </div>
                        <div class="col-6">
                          <center>
                            <a v-for="(item, index) in setting" :key="index">
                              <a
                                v-if="invest.currencyOUT + 'USDT' == item.name"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  color: green;
                                "
                              >
                                {{
                                  (
                                    ((invest.ratio -
                                      invest.cost_ratio * invest.period) /
                                      invest.period /
                                      (invest.cost_ratio * invest.period)) *
                                    365 *
                                    100
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}
                              </a>
                            </a>
                            %
                          </center>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <center>
                            <a style="font-weight: 600; font-size: 18px">
                              Rewards :
                            </a>
                          </center>
                        </div>
                        <div class="col-6">
                          <center>
                            <img
                              :src="
                                require(`@/assets/tokens/${invest.currencyOUT}.png`)
                              "
                              style="
                                max-width: 35px;
                                border-radius: 50%;
                                border: 2px solid green;
                                margin-left: -15px;
                              "
                            />
                            <a style="font-weight: 600">
                              {{ invest.currencyOUT }}
                            </a>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div class="col-1"></div>
                  </div>

                  <br />
                  <br />
                  <p style="font-weight: 500">Conditions:</p>
                  <div class="ml-3" style="font-size: 14px">
                    <li>
                      Minimum {{ invest.type }}
                      <a style="color: #14b3f9; font-weight: 500">
                        {{ invest.min_invest }}
                      </a>
                      {{ invest.currencyIN }}
                    </li>
                    <li>
                      Liquidity Pool Return after
                      <a style="color: #14b3f9; font-weight: 500"
                        >{{ invest.period }}
                      </a>
                      Days
                    </li>
                    <li>
                      Daily Rewards
                      <a style="color: #14b3f9; font-weight: 500">
                        {{
                          parseFloat(invest.ratio / invest.period).toFixed(2)
                        }}
                      </a>
                      {{ invest.currencyOUT }} / {{ invest.currencyIN }}
                    </li>
                    <li>
                      STAKING Supply
                      <a style="color: #14b3f9; font-weight: 500">
                        {{ invest.cost_ratio }}
                      </a>
                      {{ invest.currencyCOST }} per Day
                    </li>
                  </div>
                  <div>
                    <br />
                    <a style="font-size: 14px; font-weight: 500"
                      >Amount {{ invest.currencyIN }} ( Liquidity Pool )</a
                    >
                    <br />
                    <br />
                    <fg-input
                      v-model="amountPowerCoin"
                      type="number"
                      :placeholder="
                        'Enter ' +
                        invest.currencyIN +
                        ' Amount | Available : ' +
                        invest.avail +
                        ' ' +
                        invest.currencyIN
                      "
                    >
                    </fg-input>
                    <div class="row justify-content-end">
                      <div class="mr-3">
                        <p style="font-size: 14px">
                          <a style="color: #14b3f9">
                            {{
                              parseFloat(
                                getBalance[invest.currencyIN].available
                              ).toFixed(2)
                            }}
                          </a>

                          {{ invest.currencyIN }}
                        </p>
                      </div>
                    </div>

                    <div class="row justify-content-end">
                      <div class="mr-3 mt-n1">
                        <p style="font-size: 14px">
                          <a style="color: #14b3f9">
                            {{
                              parseFloat(getBalance.HBX.available).toFixed(2)
                            }}
                          </a>
                          ( HBX )
                        </p>
                      </div>
                    </div>

                    <div class="row justify-content-end">
                      <div class="mr-3 mt-n2">
                        <p style="font-size: 12px">
                          <a
                            style="
                              text-decoration-line: underline;
                              color: #818080;
                            "
                            href="https://exchange.pancakeswap.finance/#/add/BNB/0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                          >
                            Get {{ invest.currencyIN }} LP tokens
                          </a>
                        </p>
                      </div>
                    </div>

                    <p style="font-weight: 500">Approximately:</p>
                    <div class="ml-3" style="font-size: 14px">
                      <li>
                        {{ invest.type }}
                        <a
                          style="
                            color: #14b3f9;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          >{{ amountPowerCoin }}</a
                        >
                        {{ invest.currencyIN }}
                      </li>
                      <li>
                        STAKING Supply
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            (
                              amountPowerCoin *
                              invest.cost_ratio *
                              invest.period
                            ).toFixed(2)
                          }}
                        </a>
                        {{ invest.currencyCOST }} per {{ invest.period }} Days
                      </li>
                      <li>
                        Daily Rewards
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            parseFloat(
                              (invest.ratio / invest.period) * amountPowerCoin
                            ).toFixed(2)
                          }}
                        </a>
                        {{ invest.currencyOUT }}
                      </li>

                      <li>
                        Total Rewards 15 Days
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            parseFloat(invest.ratio * amountPowerCoin).toFixed(
                              2
                            )
                          }}
                        </a>
                        {{ invest.currencyOUT }}
                      </li>
                    </div>

                    <br />

                    <center>
                      <div>
                        <l-button
                          type="submit"
                          class="btn btn-fill btn-info"
                          size="lg"
                          @click="submitInvest(invest)"
                        >
                          STAKING POOL
                        </l-button>
                      </div>
                    </center>
                  </div>
                </form>
              </card>
            </div>
          </div>
        </div>
      </div> -->

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";

import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import investbox from "@/api/investbox";
import user from "@/api/user";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricePer: "300.000",
      listInvest: [],
      amountPowerCoin: "",
      priceDOGEUSDT: 0,
      priceHBXUSDT: 0,
      setting: {},
      expanded: null,
    };
  },
  computed: mapGetters(["getUser", "getBalance"]),
  methods: {
    ...mapActions(["Logout", "fetchBalance"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    async investList() {
      await investbox
        .list()
        .then((res) => {
          this.listInvest = res.data;
        })
        .catch((error) => {
          alert("err");
        });
    },
    submitInvest(invest) {
      if (this.amountPowerCoin <= 0) {
        this.alertText(
          `Amount minimum 1 Power hash`,
          `Plase amount 1 Power hash`
        );
      } else {
        if (
          this.amountPowerCoin >
          parseFloat(this.getBalance[invest.currencyIN].available)
        ) {
          this.alertText(`You don't have enough Balance`);
        } else {
          if (
            (this.amountPowerCoin * invest.cost_ratio * invest.period).toFixed(
              4
            ) > parseFloat(this.getBalance.HBX.available)
          ) {
            this.alertText(
              `You don't have enough Balance`,
              `You don't have enough Balance`
            );
          } else {
            Swal.fire({
              title: `${invest.type} ${this.amountPowerCoin} ${
                invest.currencyIN
              } pay ${(
                this.amountPowerCoin *
                invest.cost_ratio *
                invest.period
              ).toFixed(2)} ${invest.currencyCOST} to earn ${parseFloat(
                (invest.ratio / invest.period) * this.amountPowerCoin
              ).toFixed(2)} ${
                invest.currencyOUT
              } daily rewards Capital return after ${
                invest.period
              } days, Total ${parseFloat(
                invest.ratio * this.amountPowerCoin
              ).toFixed(2)} ${invest.currencyOUT}`,
              text: `Usage of particular area carriers risks. We do not guarantee payback , income or profit. Investment carries risks such as price risk , platform and many others. Assets invested increase or decrease or even turned zero . Investors should study all information before any investment and accept risks that may occur. Moreover the company will not be responsible for any kind of loses directly or indirectly.
              

Before using services calculation of mining have to be done to check whether does it worth the cost of investment as there are factors affecting like electricity and difficulties that increase on daily bases that investor need to be concerned and study to accept risks along with terms  and conditions of the system then you can press the button to start using services

Our company reserved right and use discretely decision by oneself to edit or change terms and conditions along with details on this website or even cancellation of the agreement and access to some part of the details. The company reserved right to make any changes without notification or reasons.`,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Accepted",
              cancelButtonText: "Cancel",
              confirmButtonClass: "btn btn-success btn-fill",
              cancelButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            }).then((res) => {
              if (res.isConfirmed) {
                this.submit(
                  invest._id,
                  this.amountPowerCoin * invest.cost_ratio
                );
              }
            });
          }
        }
      }
    },
    alertText(title, subtext) {
      Swal.fire({
        title: title,
        text: subtext,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-info btn-fill",
      });
    },
    async submit(id, amount) {
      await investbox
        .submit({
          invest_id: id,
          amount: this.amountPowerCoin,
        })
        .then((res) => {
          this.fetchBalance();

          this.amountPowerCoin = null;

          setTimeout(() => {
            Swal.fire({
              title: "Success",
              text: "Create order STAKING success",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-success btn-fill",
            });
          }, 100);
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.error,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning btn-fill",
          });
        });
    },

    async getPriceHBX() {
      await user.setting().then((res) => {
        this.setting = res.data;

        res.data.find((coin) => {
          if (coin.name == "HBXUSDT") {
            this.priceHBXUSDT = parseFloat(coin.value);
          }
        });
      });
    },
    a(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    expand(tx_id) {
      if (this.expanded == tx_id) {
        this.expanded = null;
        return;
      }
      this.expanded = tx_id;
    },
  },
  created() {
    this.investList();
    this.getPriceHBX();
  },
};
</script>
