<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Transfer"></top-navbar>

      <div class="m-3 mt-3">
        <div class="row">
          <div class="col-md-6">
            <card class="stacked-form" title="">
              <a style="color: #14b3f9; font-size: 18px"
                >Transfer <a style="font-weight: 600">{{ radios.radio2 }}</a>
              </a>
              <form method="#" action="#" @submit.prevent>
                <br />
                <br />

                <div class="row">
                  <div class="col-5">
                    <img
                      :src="require(`@/assets/tokens/${radios.radio2}.png`)"
                      style="
                        border: 2px solid #14b3f9;
                        border-radius: 100px;
                        width: 100px;
                        height: 100px;
                      "
                    />
                  </div>
                  <div class="col-7">
                    <br />
                    <div class="row justify-content-end mr-2">
                      <a style="font-size: 14px"
                        >Available
                        <a
                          style="
                            font-weight: 600;
                            font-size: 16px;
                            color: #14b3f9;
                          "
                        >
                          {{
                            parseFloat(
                              getBalance[radios.radio2].available
                            ).toFixed(2)
                          }}
                        </a>
                        {{ radios.radio2 }}</a
                      >
                    </div>
                    <div class="row justify-content-end mr-2">
                      <a style="font-size: 14px"
                        >Balance
                        <a
                          style="
                            font-weight: 600;
                            font-size: 16px;
                            color: #14b3f9;
                          "
                        >
                          {{
                            parseFloat(
                              getBalance[radios.radio2].balance
                            ).toFixed(2)
                          }}
                        </a>
                        {{ radios.radio2 }}</a
                      >
                    </div>
                  </div>
                </div>

                <br />
                <br />
                <br />

                <div class="row">
                  <div class="col-6">
                    <radio label="CRD" v-model="radios.radio2">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/CRD.png`)"
                      />
                      CRD
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="HBX" v-model="radios.radio2"
                      ><img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/HBX.png`)"
                      />
                      HBX</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="BNB" v-model="radios.radio2">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/BNB.png`)"
                      />
                      BNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="BUSD" v-model="radios.radio2">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/BUSD.png`)"
                      />
                      BUSD</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="DOGE" v-model="radios.radio2">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/DOGE.png`)"
                      />
                      DOGE</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="HBXBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/HBXBNB.png`)"
                      />
                      HBXBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="HBXDOGE" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/HBXDOGE.png`)"
                      />
                      HBXDOGE</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="CAKEBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/CAKEBNB.png`)"
                      />
                      CAKEBNB</radio
                    >
                  </div>

                  <div class="col-6">
                    <radio label="CTKBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/CTKBNB.png`)"
                      />
                      CTKBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="SFPBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/SFPBNB.png`)"
                      />
                      SFPBNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="ALPHABNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/ALPHABNB.png`)"
                      />
                      ALPHABNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="BANDBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/BANDBNB.png`)"
                      />
                      BANDBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="XRPBNB" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/XRPBNB.png`)"
                      />
                      XRPBNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="ALPACABUSD" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/ALPACABUSD.png`)"
                      />
                      ALPACABUSD</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="MGOOGLUST" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MGOOGLUST.png`)"
                      />
                      MGOOGLUST
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="MTSLAUST" v-model="radios.radio2">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MTSLAUST.png`)"
                      />
                      MTSLAUST</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="MNFLXUST" v-model="radios.radio2"
                      ><img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MNFLXUST.png`)"
                      />
                      MNFLXUST</radio
                    >
                  </div>
                </div>

                <div>
                  <fg-input
                    label="To Email address"
                    type="email"
                    placeholder="Enter email"
                    v-model="addressCRD"
                  >
                  </fg-input>
                  <fg-input
                    :label="'Amount ( ' + radios.radio2 + ' )'"
                    :placeholder="'Enter ' + radios.radio2 + ' Amount'"
                    type="Number"
                    v-model="amountCRD"
                  >
                  </fg-input>

                  <a style="font-size: 14px">
                    Minimum: 1.00 {{ radios.radio2 }}, fee: 0.00
                    {{ radios.radio2 }}</a
                  >
                  <br />

                  <center>
                    <button
                      style="background-color: #70cfa3"
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="sendCRD()"
                    >
                      Send {{ radios.radio2 }}
                    </button>
                  </center>
                </div>
              </form>
            </card>
          </div>
          <div class="col-md-6">
            <card class="stacked-form" title="">
              <a style="color: #14b3f9; font-size: 18px">Transfer Power Hash</a>
              <form method="#" action="#" @submit.prevent>
                <br />
                <br />
                <div class="row">
                  <div class="col-6">
                    <img
                      :src="require(`@/assets/tokens/${radios.radio1}THs.png`)"
                      style="
                        background-color: #f6a821;
                        border: 2px solid #14b3f9;
                        border-radius: 100px;
                        width: 100px;
                        height: 100px;
                      "
                    />
                    <div>
                      <a
                        class="ml-4"
                        style="font-weight: 600; font-size: 28px; color: white"
                      >
                        {{ radios.radio1 }}
                      </a>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="radios.radio1 == 'S9'">
                      <div class="row justify-content-end mr-2">
                        <a style="font-size: 14px"
                          >Available
                          <a
                            style="
                              font-weight: 600;
                              font-size: 16px;
                              color: #14b3f9;
                            "
                          >
                            {{
                              parseFloat(getBalance.S9THs.available).toFixed(2)
                            }}
                          </a>
                          TH/s</a
                        >
                      </div>
                      <div class="row justify-content-end mr-2">
                        <a style="font-size: 14px"
                          >Balance
                          <a
                            style="
                              font-weight: 600;
                              font-size: 16px;
                              color: #14b3f9;
                            "
                          >
                            {{
                              parseFloat(
                                parseFloat(getBalance.S9THs.balance) +
                                  parseFloat(amounMiningS9)
                              ).toFixed(2)
                            }}
                          </a>
                          TH/s</a
                        >
                      </div>
                    </div>
                    <div v-else-if="radios.radio1 == 'S19'">
                      <div class="row justify-content-end mr-2">
                        <a style="font-size: 14px"
                          >Available
                          <a
                            style="
                              font-weight: 600;
                              font-size: 16px;
                              color: #14b3f9;
                            "
                          >
                            {{
                              parseFloat(getBalance.S19THs.available).toFixed(2)
                            }}
                          </a>
                          TH/s</a
                        >
                      </div>
                      <div class="row justify-content-end mr-2">
                        <a style="font-size: 14px"
                          >Balance
                          <a
                            style="
                              font-weight: 600;
                              font-size: 16px;
                              color: #14b3f9;
                            "
                          >
                            {{
                              parseFloat(getBalance.S19THs.balance).toFixed(2)
                            }}
                          </a>
                          TH/s</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div class="row">
                  <div class="col-md-4">
                    <radio label="S9" v-model="radios.radio1"
                      >POWER HASH ( S9 )
                    </radio>
                  </div>
                  <div class="col-md-4">
                    <radio label="S19" v-model="radios.radio1"
                      >POWER HASH ( S19 )</radio
                    >
                  </div>
                </div>

                <div>
                  <fg-input
                    label="To Email address"
                    type="email"
                    placeholder="Enter email"
                    v-model="addressPower"
                  >
                  </fg-input>
                  <fg-input
                    v-model="amontPower"
                    label="POWER"
                    placeholder="Enter POWER amount"
                    type="Number"
                  >
                  </fg-input>

                  <a style="font-size: 14px">
                    Minimum: 1.00 {{ radios.radio1 }}, fee: 0.00
                    {{ radios.radio1 }}</a
                  >
                  <br />

                  <center>
                    <button
                      style="background-color: #f6a821"
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="sendPowerHash()"
                    >
                      Send POWER ( {{ radios.radio1 }} )
                    </button>
                  </center>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";

import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import user from "@/api/user";

import investbox from "@/api/investbox";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Card, Table as LTable, Radio } from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    Radio,
  },
  data() {
    return {
      amountCRD: "",
      addressCRD: "",
      addressPower: "",
      amontPower: "",
      radios: {
        radio1: "S9",
        radio2: "CRD",
      },
      factor: null,
      amounMiningS9: 0,
    };
  },
  computed: mapGetters(["getUser", "getBalance"]),
  methods: {
    ...mapActions(["Logout", "fetchBalance"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },

    async sendCRD() {
      if (
        this.amountCRD == "" ||
        this.addressCRD == "" ||
        parseFloat(this.amountCRD) < 0
      ) {
        this.showErr();
      } else {
        if (
          parseFloat(this.getBalance[this.radios.radio2].available).toFixed(2) <
          parseFloat(this.amountCRD)
        ) {
          this.showErr();
        } else {
          await user
            .transfer({
              to: this.addressCRD,
              amount: this.amountCRD,
              currency: this.radios.radio2,
              twofa: this.factor,
            })
            .then((res) => {
              this.amountCRD = "";
              this.fetchBalance();
              this.showSuccess();
            })
            .catch((error) => {
              Swal.fire({
                title: "error.response.data.error",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning btn-fill",
              });
            });
        }
      }
    },
    showErr() {
      Swal.fire({
        title: "You don't have enough Balance",
        type: "danger",
        confirmButtonClass: "btn btn-danger",
        buttonsStyling: false,
      });
    },

    showSuccess() {
      Swal.fire({
        title: "Transfer success",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
      });
    },

    async sendPowerHash() {
      if (
        this.amontPower == "" ||
        this.addressPower == "" ||
        parseFloat(this.amontPower) < 0
      ) {
        this.showErr();
      } else {
        var amount_send = "";
        let nameCoin = "";
        if (this.radios.radio1 == "S9") {
          nameCoin = "S9THs";
          amount_send = this.getBalance.S9THs.available;
        } else if (this.radios.radio1 == "S19") {
          nameCoin = "S19THs";
          amount_send = this.getBalance.S19THs.available;
        }

        if (parseFloat(this.amontPower) > parseFloat(amount_send)) {
          this.showErr();
        } else {
          await user
            .transfer({
              to: this.addressPower,
              amount: this.amontPower,
              currency: nameCoin,
              twofa: this.factor,
            })
            .then((res) => {
              this.amontPower = "";
              this.fetchBalance();
              this.showSuccess();
            })
            .catch((error) => {});
        }
      }
    },
    getInves() {
      let inMiningS9 = 0;
      investbox
        .contract()
        .then((res) => {
          res.data.map((con) => {
            if (con.status != "COMPLETED" && con.status != "COMPLETE") {
              if (con.currencyIN == "S9THs") {
                inMiningS9 = inMiningS9 + parseFloat(con.amount);
              }
            }
          });

          this.amounMiningS9 = inMiningS9;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getInves();
  },
};
</script>
