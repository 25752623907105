<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Profile"></top-navbar>

      <div class="mt-3">
        <div class="row d-flex justify-content-center">
          <div class="col-sm-11 m-2">
            <card class="stacked-form">
              <form method="#" action="#">
                <p style="font-weight: 600; font-size: 22px; color: #14b3f9">
                  General Profile
                </p>
                <div style="font-size: 14px">
                  <p style="font-weight: 600; color: #14b3f9">
                    Username: <a style="color: black">{{ getUser._id }}</a>
                  </p>
                  <p style="font-weight: 600; color: #14b3f9">
                    Email Address :
                    <a style="color: black">{{ getUser.email }}</a>
                  </p>
                  <p style="font-weight: 600; color: #14b3f9">
                    Registered on date :
                    <a style="color: black">{{ getUser.createdAt }}</a>
                  </p>
                  <p></p>
                </div>
              </form>
            </card>
          </div>
          <div v-if="kyc == false" class="col-sm-8">
            <div class="card card-wizard" id="">
              <form-wizard
                title="Verification guide on HashBX"
                subtitle="HashBX.com is obliged to follow AML and KYC policies of the bank and we aim at eliminating any third-party transactions."
                shape="tab"
                @on-complete="wizardComplete"
                error-color="#FB404B"
                color="#35495E"
              >
                <tab-content
                  title="Personal details"
                  class="col-12"
                  :before-change="() => validateStep('firstStep')"
                  @on-validated="onStepValidated"
                  icon="nc-icon nc-badge"
                >
                  <first-step
                    ref="firstStep"
                    @on-complete="wizardComplete"
                  ></first-step>
                </tab-content>

                <button
                  slot="prev"
                  class="btn btn-default btn-fill btn-wd btn-back"
                >
                  Back
                </button>
                <button
                  slot="next"
                  class="btn btn-default btn-fill btn-wd btn-next"
                >
                  Next
                </button>
                <button slot="finish" class="btn btn-success btn-fill btn-wd">
                  Finish
                </button>
              </form-wizard>
            </div>
          </div>

          <div v-else class="col-sm-11 m-2">
            <card class="stacked-form">
              <p style="font-weight: 600; font-size: 22px">KYC Verification</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="m-3">
                    <div>
                      <fg-input
                        label="Your Information	"
                        placeholder="Name : Mr. Siwakorn Sirisom"
                        v-model="nameUser"
                        disabled
                      >
                      </fg-input>
                    </div>
                    <div :style="approveBackground">
                      <br />

                      <center>
                        <div v-if="userKYC.status == 'wait'">
                          <i
                            class="fa fa-hourglass-half"
                            style="font-size: 50px; color: white"
                          ></i>
                        </div>
                        <div v-else-if="userKYC.status == 'approve'">
                          <i
                            class="fa fa-check"
                            style="font-size: 50px; color: white"
                          ></i>
                        </div>
                        <div v-else>
                          <i
                            class="fa fa-exclamation-circle"
                            style="font-size: 50px; color: white"
                          ></i>
                        </div>

                        <br />

                        <p
                          style="
                            color: white;
                            font-size: 24px;
                            font-weight: 600;
                          "
                        >
                          {{ textTitle }}
                        </p>
                        <p style="color: white; font-size: 14px">
                          We will be happy to mine bitcoin forever !
                        </p>
                        <br />
                      </center>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="m-3">
                    <p style="font-size: 14px; font-weight: 600">
                      Authorized Proof !
                    </p>
                    <p style="font-size: 14px; font-weight: 500">
                      Unlock Options
                    </p>
                    <div class="ml-2">
                      <a style="font-size: 14px"> - Allow Send Bitcoin </a>
                      <br />
                      <a style="font-size: 14px"> - Allow Transfer CRD </a>
                      <br />
                      <a style="font-size: 14px">
                        - Allow Transfer PowerHash
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="userKYC.status == 'reject'">
                <br />
                <center>
                  <p style="font-size: 18px; font-weight: 600; color: red">
                    *** Remark ***
                  </p>
                  <p>{{ userKYC.remark }}</p>
                </center>
              </div>
            </card>
          </div>
          <div v-if="kycReject" class="col-sm-8">
            <div class="card card-wizard" id="">
              <form-wizard
                title="Verification guide on HashBX"
                subtitle="HashBX.com is obliged to follow AML and KYC policies of the bank and we aim at eliminating any third-party transactions."
                shape="tab"
                @on-complete="wizardComplete"
                error-color="#FB404B"
                color="#35495E"
              >
                <tab-content
                  title="Personal details"
                  class="col-12"
                  :before-change="() => validateStep('firstStep')"
                  @on-validated="onStepValidated"
                  icon="nc-icon nc-badge"
                >
                  <first-step
                    ref="firstStep"
                    :userKYC="userKYC"
                    @on-complete="wizardComplete"
                  ></first-step>
                </tab-content>

                <button
                  slot="prev"
                  class="btn btn-default btn-fill btn-wd btn-back"
                >
                  Back
                </button>
                <button
                  slot="next"
                  class="btn btn-default btn-fill btn-wd btn-next"
                >
                  Next
                </button>
                <button slot="finish" class="btn btn-success btn-fill btn-wd">
                  Finish
                </button>
              </form-wizard>
            </div>
          </div>
          <diV class="col-md-11">
            <card class="stacked-form m-2">
              <p style="font-size: 18px; font-weight: 600; color: #14b3f9">
                Verification guide on HashBX
              </p>
              <div>
                <p style="font-size: 14px">
                  HashBX.com is obliged to follow AML and KYC policies.
                </p>
              </div>

              <div>
                <p style="font-size: 14px; font-weight: 600; color: #14b3f9">
                  Fully verified user gets access to:
                </p>
                <div class="ml-3">
                  <p style="font-size: 14px">- option to send bitcoin</p>
                  <p style="font-size: 14px">- option to transfer crd</p>
                  <p style="font-size: 14px">- option to transfer powerhash</p>
                </div>
                <br />
                <div>
                  <p style="font-size: 18px; font-weight: 600; color: #14b3f9">
                    Step 1:
                    <a style="color: black"
                      >Send Verification of KYC Information</a
                    >
                  </p>
                  <p style="font-size: 18px; font-weight: 600; color: #14b3f9">
                    Step 2:
                    <a style="color: black"
                      >Waiting for approval from staff is processing by admin</a
                    >
                  </p>
                </div>
              </div>
            </card>
          </diV>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FirstStep from "./Wizard/FirstStep.vue";
import SecondStep from "./Wizard/SecondStep.vue";
import Swal from "sweetalert2";
import axios from "axios";

import { mapGetters } from "vuex";

import { API_URL } from "../../../config";

export default {
  data() {
    return {
      wizardModel: {},
      userKYC: {},
      kyc: false,
      kycReject: false,
      status: "wait",
      nameUser: "",
      approveBackground: "",
      textTitle: "",
    };
  },
  components: {
    FormWizard,
    TabContent,
    FirstStep,
    SecondStep,
    TopNavbar,
    ContentFooter,
  },
  computed: mapGetters(["getUser", "getBalance"]),
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      Swal.fire({
        title: "Good job!",
        text: "You clicked the finish button!",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
      });
      this.loadDataKYC();
    },
    loadDataKYC() {
      axios
        .get(`${API_URL}/search/kyc/${this.getUser._id}`)
        .then((response) => {
          if (response.data == null || response.data == undefined) {
            this.kyc = false;
          } else {
            this.kyc = true;

            this.userKYC = response.data.userKYC;

            this.nameUser = this.userKYC.name;
            if (this.userKYC.status == "approve") {
              this.approveBackground = "background-color: #76F52E";
              this.textTitle = "This account is approved";
              this.kycReject = false;
            } else if (this.userKYC.status == "reject") {
              this.approveBackground = "background-color: #DF4210";
              this.textTitle = "This account is reject";
              this.kycReject = true;
            } else if (this.userKYC.status == "wait") {
              this.approveBackground = "background-color: #F7C039";
              this.textTitle = "This account is wait approved";
              this.kycReject = false;
            }
          }
        })
        .catch((error) => {
          if (error.response.data.erro) {
            Swal.fire({
              title: error.response.data.error,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning btn-fill",
            });
          }
        });
    },
  },
  created() {
    this.loadDataKYC();
  },
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
</style>
