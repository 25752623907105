<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="My Earn"></top-navbar>

      <div class="m-1 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <card>
              <div slot="header">
                <h4 class="card-title">My Pool</h4>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-8 m-2">
                  <div class="row">
                    <div class="col-5">
                      <a style="font-weight: 500">Pool </a>
                    </div>
                    <div class="col-3">
                      <a style="font-weight: 500">Type </a>
                    </div>
                    <div class="col-4">
                      <center>
                        <a style="font-weight: 500">Status </a>
                      </center>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-10 m-2"
                  v-for="(item, index) in history_rows"
                  :key="index"
                >
                  <template>
                    <div v-on:click="expand(item.hash)">
                      <div class="row">
                        <div class="col-5">
                          <a style="font-size: 12px; font-weight: 600"
                            >{{ item.currencyIN }}
                            <i
                              class="fa fa-chevron-circle-right"
                              style="color: green; font-size: 20px"
                            ></i>
                            <a style="font-weight: 500">
                              {{ item.currencyOUT }} [ {{ item.day }}/{{
                                item.period
                              }}
                              ] <br />
                              [ HASH_ID: {{ item.hashID }} ]
                            </a>
                          </a>
                        </div>

                        <div class="col-3">
                          <a style="font-size: 12px; font-weight: 500"
                            >{{ item.type }}
                          </a>
                        </div>
                        <div class="col-4">
                          <div v-if="item.status == 'INVESTING'">
                            <center>
                              <div
                                style="
                                  background-color: #dab51b;
                                  border-radius: 10px;
                                "
                              >
                                <a
                                  class="m-2"
                                  style="
                                    font-size: 12px;
                                    color: white;
                                    font-weight: 500;
                                  "
                                >
                                  PROCESSING
                                </a>
                              </div>
                            </center>
                          </div>
                          <div v-else-if="item.status == 'COMPLETED'">
                            <center>
                              <div
                                style="
                                  background-color: #1bb433;

                                  border-radius: 25px;
                                "
                              >
                                <a
                                  class="m-2"
                                  style="
                                    font-size: 12px;
                                    color: white;
                                    font-weight: 500;
                                  "
                                  >{{ item.status }}
                                </a>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-if="expanded == item.hash">
                    <div class="table-responsive table-full-width">
                      <el-table class="table-striped" :data="history_mining">
                        <el-table-column property="createdAt"></el-table-column>
                        <el-table-column property="remark"></el-table-column>
                        <el-table-column property="currency"></el-table-column>
                        <el-table-column property="amount"></el-table-column>
                        <el-table-column>
                          <template slot-scope="props">
                            <div v-if="props.row.type == 'INCOME'">
                              <a>REWARDS</a>
                            </div>
                            <div v-else>
                              <a>{{ props.row.type }}</a>
                            </div>
                          </template>
                          <a></a>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>

                  <hr />
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";

import user from "@/api/user";
import admin from "@/api/admin";

import moment from "moment";

import { mapGetters, mapActions } from "vuex";

import label from "@/widgets/label";
import investbox from "@/api/investbox";
import func from "@/widgets/func";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricePer: "300.000",
      table_rows: [],
      period: null,
      createdAt: null,
      countdown: null,
      ready: false,
      filter: "",
      history_mining: [],
      history_rows: [],
      page: 1,
      per_page: 19,
      expanded: null,
    };
  },
  computed: mapGetters(["getUser", "getAllInvest"]),

  methods: {
    ...mapActions(["Logout", "fetchAllInvest"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    fixed(a) {
      return func.fixed(a);
    },
    expand(tx_id) {
      if (this.expanded == tx_id) {
        this.expanded = null;
        return;
      }
      this.getTransaction(tx_id);
      this.expanded = tx_id;
    },
    getRemaining(period, day) {
      if (period - day > 0) {
        return `${period - day} payment`;
      } else {
        return "COMPLETE";
      }
    },

    async getTransaction(tx_id) {
      await admin
        .txrelation({ tx_id })
        .then((res) => {
          this.history_mining = [];
          this.ready = true;
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            var a = label.type(e.type);
            e.label = a.label;
            this.history_mining.push(e);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getdata() {
      investbox
        .contract()
        .then((res) => {
          this.history_rows = [];
          this.ready = true;
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("Y-MM-DD H:mm:s");

            var a = label.type(e.status);
            e.label = a.label;

            let countAmountHash = parseInt(e.hash.length);

            e.hashID =
              e.hash.slice(0, 6) +
              "........" +
              e.hash.slice(countAmountHash - 7, countAmountHash - 1);

            this.history_rows.push(e);
          });
        })
        .catch((error) => {});
    },
    mounted() {
      this.getdata();
    },
  },
  created() {
    this.getdata();

    this.fetchAllInvest();
  },
};
</script>
