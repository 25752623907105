var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'ADD MONEY',
          icon: 'fa fa-money',
          path: '/adminaddmoney',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'KYC Verification',
          icon: 'fa fa-user',
          path: '/adminkyc',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Withdrawals List',
          icon: 'fa fa-key',
          path: '/adminwithdrawalslist',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contract',
          icon: 'fa fa-signal',
          path: '/adminccontract',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSACTIONS',
          icon: 'fa fa-calendar',
          path: '/admintransactions',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'View User',
          icon: 'fa fa-user',
          path: '/adminviewuser',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'All Deposit',
          icon: 'fa fa-gift',
          path: '/admindepositall',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Fund Summary',
          icon: 'fa fa-bar-chart',
          path: '/adminfund',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{staticClass:"m-1 mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title",staticStyle:{"color":"#14b3f9","font-weight":"500"}},[_vm._v(" TRANSACTIONS ")])]),_c('div',{staticClass:"table-responsive table-full-width"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.table_rows}},[_c('el-table-column',{attrs:{"property":"createdAt"}}),_c('el-table-column',{attrs:{"property":"remark"}}),_c('el-table-column',{attrs:{"property":"currency"}}),_c('el-table-column',{attrs:{"property":"amount"}}),_c('el-table-column',{attrs:{"property":"type"}})],1)],1)])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }