<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Withdrawal"></top-navbar>

      <div class="m-3 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <card class="stacked-form" title="">
              <a style="color: #14b3f9; font-size: 18px"
                >Withdrawal BEP20 Chain
              </a>
              <form method="#" action="#" @submit.prevent>
                <center>
                  <img
                    style="max-width: 60px"
                    :src="require(`@/assets/tokens/${radios.radio1}.png`)"
                  />
                  <br />

                  <a style="font-size: 24px">
                    <a style="font-size: 14px">Available</a>
                    <a style="color: #14b3f9; font-weight: 600">
                      {{ getBalance[radios.radio1].available }}
                    </a>
                    <a style="font-size: 14px">{{ radios.radio1 }}</a>
                  </a>
                  <br />
                  <a style="font-size: 24px; font-weight: 600">
                    <a style="font-size: 14px">Balance</a>
                    <a style="color: #14b3f9; font-weight: 600">
                      {{ getBalance[radios.radio1].balance }}
                    </a>
                    <a style="font-size: 14px">{{ radios.radio1 }}</a>
                  </a>
                  <br />
                </center>
                <br />
                <div class="row">
                  <div class="col-6">
                    <radio label="HBX" v-model="radios.radio1">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/HBX.png`)"
                      />
                      HBX
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="BNB" v-model="radios.radio1">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/BNB.png`)"
                      />BNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="BUSD" v-model="radios.radio1">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/BUSD.png`)"
                      />BUSD</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="DOGE" v-model="radios.radio1">
                      <img
                        style="max-width: 25px"
                        :src="require(`@/assets/tokens/DOGE.png`)"
                      />DOGE</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="HBXBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/HBXBNB.png`)"
                      />HBXBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="HBXDOGE" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/HBXDOGE.png`)"
                      />HBXDOGE</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="CAKEBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/CAKEBNB.png`)"
                      />CAKEBNB</radio
                    >
                  </div>

                  <div class="col-6">
                    <radio label="CTKBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/CTKBNB.png`)"
                      />CTKBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="SFPBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/SFPBNB.png`)"
                      />SFPBNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="ALPHABNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/ALPHABNB.png`)"
                      />ALPHABNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="BANDBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/BANDBNB.png`)"
                      />BANDBNB
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="XRPBNB" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/XRPBNB.png`)"
                      />XRPBNB</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="ALPACABUSD" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/ALPACABUSD.png`)"
                      />ALPACABUSD</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="MGOOGLUST" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MGOOGLUST.png`)"
                      />MGOOGLUST
                    </radio>
                  </div>
                  <div class="col-6">
                    <radio label="MTSLAUST" v-model="radios.radio1">
                      <img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MTSLAUST.png`)"
                      />MTSLAUST</radio
                    >
                  </div>
                  <div class="col-6">
                    <radio label="MNFLXUST" v-model="radios.radio1"
                      ><img
                        style="max-width: 30px"
                        :src="require(`@/assets/tokens/MNFLXUST.png`)"
                      />MNFLXUST</radio
                    >
                  </div>
                </div>

                <br />

                <br />

                <div>
                  <form :onchange="changeAmount">
                    <a>
                      To {{ radios.radio1 }} address ( BEP20 ) |
                      <a style="font-weight: 600">
                        <img
                          src="https://media-exp1.licdn.com/dms/image/C560BAQHP3cKU-xh1NA/company-logo_200_200/0/1607914609039?e=2159024400&v=beta&t=SvFToeFl187kUuSfuH_zK0bQedaZcEZrzTqBjB5WaHc"
                          style="
                            width: 30px;
                            border-radius: 50%;
                            border: 2px solid black;
                          "
                        />
                        SafePal</a
                      >
                      wallet recommend
                    </a>
                    <div
                      style="
                        width: 100%;
                        border-style: solid;
                        border-width: 2px;
                        color: #e9c11e;
                        background-color: rgba(219, 183, 76, 0.3);
                      "
                    >
                      <div class="m-2">
                        <a
                          style="
                            font-size: 14px;
                            color: #e0b70f;
                            font-weight: 500;
                          "
                        >
                          Due to HBX Token from Hashbx.com is on BEP20 of
                          Binance Smart Chain (BSC) Please ensure that the
                          withdrawal address support BSC Network You will lose
                          your assets if the withdrawal address does not support
                          BSC Network If there is any mistake Hashbx.com will
                          not be reasonable for your loss HASHBX.COM | The best
                          platform cloud mining.
                        </a>
                      </div>
                    </div>
                    <br />
                    <fg-input v-model="address" placeholder="Enter address">
                    </fg-input>
                    <fg-input
                      v-model="amount"
                      :label="'Amount ' + radios.radio1"
                      type="number"
                      placeholder="0.00000"
                      @change="changeAmount(amount)"
                    >
                    </fg-input>
                  </form>
                  <br />
                  <div class="form-group">
                    <a style="font-size: 14px">
                      Fee: {{ getCurrency[radios.radio1].withdraw_fee }}
                      {{ radios.radio1 }}, Receive:

                      <a
                        style="color: black; font-size: 16px; font-weight: 600"
                        >{{
                          (
                            amount - getCurrency[radios.radio1].withdraw_fee
                          ).toFixed(8)
                        }}</a
                      >
                      {{ radios.radio1 }} ( Minimum:
                      {{ getCurrency[radios.radio1].min_deposit }}
                      {{ radios.radio1 }} )
                    </a>
                    <br />
                  </div>
                  <center>
                    <button
                      style=""
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="submit(radios.radio1)"
                    >
                      Send {{ radios.radio1 }}
                    </button>
                  </center>
                </div>
              </form>
            </card>
          </div>
          <div class="col-md-6">
            <card class="stacked-form" title="">
              <a style="color: #14b3f9; font-size: 18px"
                >Withdrawal {{ radios.radio2 }}
              </a>
              <form method="#" action="#" @submit.prevent>
                <center>
                  <img
                    style="max-width: 60px"
                    :src="require(`@/assets/tokens/${radios.radio2}.png`)"
                  />
                  <br />

                  <a style="font-size: 24px">
                    <a style="font-size: 14px">Available</a>
                    <a style="color: #14b3f9; font-weight: 600">
                      {{ getBalance[radios.radio2].available }}
                    </a>
                    <a style="font-size: 14px">{{ radios.radio2 }}</a>
                  </a>
                  <br />
                  <a style="font-size: 24px; font-weight: 600">
                    <a style="font-size: 14px">Balance</a>
                    <a style="color: #14b3f9; font-weight: 600">
                      {{ getBalance[radios.radio2].balance }}
                    </a>
                    <a style="font-size: 14px">{{ radios.radio2 }}</a>
                  </a>
                  <br />
                </center>
                <br />
                <div class="row">
                  <div class="col-md-2">
                    <radio label="BTC" v-model="radios.radio2">BTC </radio>
                  </div>
                </div>
                <br />

                <div>
                  <form :onchange="changeAmount">
                    <fg-input
                      :label="'To ' + radios.radio2 + 'BTC address'"
                      v-model="addressBTC"
                      placeholder="Enter BTC address"
                    >
                    </fg-input>
                    <fg-input
                      v-model="amount"
                      :label="'Amount ' + radios.radio2"
                      type="number"
                      placeholder="0.00000"
                      @change="changeAmount(amount)"
                    >
                    </fg-input>
                  </form>
                  <br />
                  <div class="form-group">
                    <a style="font-size: 14px">
                      Fee: {{ getCurrency[radios.radio2].withdraw_fee }}
                      {{ radios.radio2 }}, Receive:
                      <a
                        v-if="
                          amount == amountAvailable || amount < amountAvailable
                        "
                        style="color: black; font-size: 16px; font-weight: 600"
                        >{{
                          (
                            amount - getCurrency[radios.radio2].withdraw_fee
                          ).toFixed(8)
                        }}</a
                      >
                      <a
                        v-else
                        style="color: black; font-size: 16px; font-weight: 600"
                        >{{
                          (
                            amount - getCurrency[radios.radio2].withdraw_fee
                          ).toFixed(8)
                        }}</a
                      >
                      {{ radios.radio2 }} ( Minimum:
                      {{ getCurrency[radios.radio2].min_deposit }}
                      {{ radios.radio2 }} )
                    </a>
                    <br />
                  </div>
                  <center>
                    <button
                      style="background-color: green"
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="submit(radios.radio2)"
                    >
                      Send {{ radios.radio2 }}
                    </button>
                  </center>
                </div>
              </form>
            </card>
          </div>
        </div>

        <div class="m-1 mt-3" v-if="table_rows.length > 0">
          <div class="row">
            <div class="col-md-12">
              <card>
                <div slot="header">
                  <h4 class="card-title">Transactions</h4>
                </div>
                <div class="table-responsive table-full-width">
                  <el-table class="table-striped" :data="table_rows">
                    <el-table-column property="createdAt"></el-table-column>
                    <el-table-column>
                      <div class="td-actions" slot-scope="props">
                        <a
                          v-tooltip.top-center="'address'"
                          class="btn btn-warning btn-link btn-xs"
                          :href="
                            'https://btc1.trezor.io/address/' +
                            props.row.address
                          "
                          target="_blink"
                        >
                          {{ props.row.address }}
                        </a>
                      </div>
                    </el-table-column>
                    <el-table-column property="amount"></el-table-column>
                    <el-table-column property="currency"></el-table-column>
                    <el-table-column property="status"></el-table-column>
                  </el-table>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Radio,
  },
  data() {
    return {
      pricePer: "300.000",
      address: "",
      addressBTC: "",
      amount: "",
      power_hashGH: 0,
      power_supply: 0,
      valueBTC: {},
      priceBTC: 0,
      amountReceive: "--",
      amountAvailable: 0,
      table_rows: [],
      amounMiningS9: 0,
      amounMiningS19: 0,
      priceBTCUSDT: 0,
      amountBTC_convert: 0,
      radios: {
        radio1: "HBX",
        radio2: "BTC",
      },
    };
  },
  computed: mapGetters(["getUser", "getBalance", "getCurrency"]),
  methods: {
    ...mapActions(["Logout", "setUser", "fetchBalance", "fetchFee"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    fetchUser() {
      axios
        .get(`${API_URL}/user/detail/${this.getUser._id}`)
        .then((response) => {
          this.setUser(response.data.users);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getPrice() {
      await user.setting().then((res) => {
        this.priceBTC = res.data.find((coin) => {
          return coin.name == "BTCUSDT";
        });
        if (this.getBalance.BTC.balance == 0) {
          this.valueBTC = 0;
        } else {
          this.valueBTC = (
            this.priceBTC.value * this.getBalance.BTC.balance
          ).toFixed(2);
        }
      });
    },
    changeAmount(e) {
      this.amountReceive = (this.amount - 0.001).toFixed(8);
    },
    maxValue() {
      this.amount = this.getBalance.BTC.available;
      this.amountReceive = (this.amount - 0.001).toFixed(8);
    },
    submit(hbx_coin) {
      let add = "";

      if (hbx_coin == "BTC") {
        add = this.addressBTC;
      } else {
        add = this.address;
      }

      if (
        this.amount != "" &&
        (this.address != "" || this.addressBTC != "") &&
        this.amount > 0
      ) {
        this.amount = parseFloat(this.amount).toFixed(8);

        let coin_widthdraw = "";

        if (hbx_coin) {
          coin_widthdraw = hbx_coin;
        } else {
          coin_widthdraw = this.radios.radio2;
        }

        user
          .withdraw({
            currency: coin_widthdraw,
            user_id: this.getUser._id,
            amount: this.amount,
            address: add,
          })
          .then((res) => {
            this.getHistory();

            this.fetchBalance();
            this.amount = "";

            Swal.fire({
              title: "Processing",
              text: "Withdrawals Processing!",
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: error.response.data.message,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning btn-fill",
            });
          });
      }
    },
    comingSoon() {
      Swal.fire({
        title: "Coming soon",
        text: "Coming soon!",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
      });
    },
    getHistory() {
      this.table_rows = [];
      user
        .withdrawhistory()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_rows.push(e);
          });
        })
        .catch((error) => {});
    },
    getdata() {
      let inMiningS9 = 0;
      let inMiningS19 = 0;

      investbox
        .contract()
        .then((res) => {
          res.data.map((con) => {
            if (con.status != "COMPLETED") {
              if (con.currencyIN == "S9THs") {
                inMiningS9 = inMiningS9 + parseFloat(con.amount);
              } else if (con.currencyIN == "S19THs") {
                inMiningS19 = inMiningS19 + parseFloat(con.amount);
              }
            }
          });

          this.amounMiningS9 = inMiningS9;
          this.amounMiningS19 = inMiningS19;
        })
        .catch((error) => {});
    },
    async convertBTC() {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => {
          this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });

      setTimeout(() => {
        if (
          this.amountBTC_convert <= 0 ||
          this.amountBTC_convert > this.getBalance.BTC.available
        ) {
          Swal.fire({
            title: "You don't have enough Balance",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-info btn-fill",
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: `You will convert ${this.amountBTC_convert} BTC to ${(
              this.priceBTCUSDT * this.amountBTC_convert
            ).toFixed(2)} CRD`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Convert",
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          }).then((res) => {
            if (res.isConfirmed == true) {
              this.convertCoin();
            }
          });
        }
      }, 500);
    },
    async convertCoin() {
      await user
        .convertCoin({
          user_id: this.getUser._id,
          currency: "BTC",
          amount: this.amountBTC_convert,
        })
        .then((data) => {
          this.fetchBalance();

          Swal.fire({
            title: "Success",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success btn-fill",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.error,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning btn-fill",
          });
        });
    },
  },
  created() {
    this.fetchBalance();
    this.fetchFee();
    this.getPrice();
    this.getHistory();
    if (this.getUser.power_hash > 0) {
      this.power_hashGH = (this.getBalance.S9THs.available * 1000).toFixed(0);
    }
    this.power_supply = (this.getUser.power_hash * 0.4).toFixed(2);
    setTimeout(() => {
      this.amountAvailable = (this.getBalance.BTC.available - 0.001).toFixed(8);
    }, 1000);

    this.getdata();

    axios
      .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
      .then((response) => {
        this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
      })
      .catch((error) => {
        console.log(error);
      });

    // this.fetchUser();
  },
};
</script>
